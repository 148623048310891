

































import { Component, Prop, Vue } from "vue-property-decorator";
import { ArticleProperty } from "@/types/shop/article";

/**
 * ArticlePropertyConfiguration component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class ArticlePropertyConfiguration extends Vue {
    // TODO required true
    @Prop({ type: Array, required: false })
    readonly properties!: ArticleProperty[];

    // TODO required true
    @Prop({ type: Object, required: false })
    readonly value!: ArticleProperty;

    private formValid = false;

    /**
     * Function to change property value
     * Emits input event
     *
     * @param value value
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private changePropertyValue(value: number) {
        this.$emit("input", {
            ...this.value,
            value: {
                ...this.value.value,
                value,
            },
        });
    }

    /**
     * Function to change property
     * Emits input event
     *
     * @param property property
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private changeProperty(property: ArticleProperty) {
        this.$emit("input", { ...property });
    }

    /**
     * Function to validate property value
     *
     * @returns boolean | string
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private validatePropertyValue(): boolean | string {
        // return true if value is not required
        if (
            this.value.value === null ||
            this.value.value.minValue === null ||
            this.value.value.maxValue === null
        )
            return true;

        // validate minValue
        if (this.value.value.value < this.value.value.minValue) {
            return this.$t("form.rules.minValue", {
                minValue: this.value.value.minValue,
            }).toString();
        }

        // validate maxValue
        if (this.value.value.value > this.value.value.maxValue) {
            return this.$t("form.rules.maxValue", {
                maxValue: this.value.value.maxValue,
            }).toString();
        }

        return true;
    }

    /**
     * Function validates form
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private validateForm() {
        // @ts-ignore
        if (typeof this.$refs?.form?.validate === "function") {
            (
                this.$refs.form as Vue & {
                    validate: () => void;
                }
            ).validate();
        }
    }
}
