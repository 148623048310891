




















































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import ArticlePriceLabel from "@/components/shop/article/price/ArticlePriceLabel.vue";
import ArticleListItem from "@/components/shop/article/list/ArticleListItem.vue";
import ArticlePropertyConfiguration from "@/components/shop/article/property/ArticlePropertyConfiguration.vue";
import AlertModule, { AlertType } from "@/store/modules/alert";
import CartModule from "@/store/modules/cart";
import axios, { APIResponse } from "@/plugins/axios";
import { Article, ArticleProperty } from "@/types/shop/article";
import ArticleDeliveryTimeLabel from "@/components/shop/article/delivery-time/ArticleDeliveryTimeLabel.vue";

/**
 * ShopArticleDetails view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        ArticleListItem,
        ArticlePriceLabel,
        ArticleDeliveryTimeLabel,
        ArticlePropertyConfiguration,
    },
})
export default class ShopArticleDetails extends Vue {
    private article: Article | null = null;
    private articleProperties: ArticleProperty[] = [];

    private quantity = 1;

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        if (!("id" in this.$route.params)) {
            this.$router.push({ name: "shop" });
        }

        this.fetchData();
    }

    /**
     * Function will be automatic called by Vue.js when locale was changed
     * Function calls fetchData
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Watch("$route.params.id")
    private onArticleIdChanged() {
        this.fetchData();
    }

    /**
     * Function will be automatic called by Vue.js when article id changed
     * Function calls fetchData
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Watch("$root.$i18n.locale")
    private onLocaleChanged() {
        this.fetchData();
    }

    /**
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchData() {
        try {
            const article = await this.fetchArticleById(
                parseInt(this.$route.params.id)
            );

            this.articleProperties = article.properties;

            const uniqueArticleProperties = article.properties.filter(
                (prop, index, array) =>
                    array.findIndex((el) => el.id === prop.id) === index
            );
            this.article = {
                ...article,
                properties: uniqueArticleProperties,
            };
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches article by id
     *
     * @param id article id
     *
     * @returns Promise<Article>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticleById(id: number): Promise<Article> {
        const response = (
            await axios.get<APIResponse<Article>>("/articles/" + id)
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Updates article property
     *
     * @param property updated property
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private updateArticleProperty(property: ArticleProperty) {
        if (this.article === null) return;
        const propertyIndex = this.article.properties.findIndex(
            (p) => p.id === property.id
        );
        if (propertyIndex === -1) return;

        Vue.set(this.article.properties, propertyIndex, property);
    }

    /**
     * Add article to cart
     *
     * @param article article
     * @param quantity quantity
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private addArticleToCart(article: Article, quantity: number) {
        CartModule.addArticle({
            commissionId: -1,
            commissionName: null,
            article,
            quantity,
        })
            .then(() => {
                this.$router.push({
                    name: "shoppingCart",
                });
            })
            .catch((err) => {
                const errorMessage =
                    err instanceof Error ? err.message : (err as string);

                AlertModule.showAlert({
                    type: AlertType.ERROR,
                    message: errorMessage,
                });
            });
    }
}
