
























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ArticleDeliveryTimeLabel extends Vue {
    @Prop({ type: Boolean, required: true }) readonly isStockArticle!: boolean;
    @Prop({ type: Number, required: true }) readonly stockCount!: number;
    @Prop({ type: Number, required: true }) readonly deliveryTime!: number;
}
